<template>
  <div class="main">
    <router-view />
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.bg-text {
  color: $HEALTH_THINK_COLOR;
}
.large-spacer {
  margin-top: 3rem;
}
.small-spacer {
  margin-top: 2rem;
}
.main {
  margin: 0;
  padding: 0;
}
</style>
