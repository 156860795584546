var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-link',{staticClass:"text-decoration-none",attrs:{"to":_vm.to},on:{"click":function($event){$event.preventDefault();return _vm.$emit('click')}}},[_c('button',{class:`'ml-n1' ${_vm.classes}

    hasHover`,style:(`min-width:${_vm.width};
              font-size: ${_vm.textSize};

      max-width:${_vm.width};
      height:${_vm.height};
      border-radius:${_vm.radius};
      color:${_vm.colorsec};
      box-shadow:${_vm.shadow};
      background-color: ${_vm.bgcolor};
      border: ${_vm.btnOutline};`),attrs:{"type":_vm.type,"disabled":_vm.loading},on:{"click":function($event){return _vm.$emit('click')}}},[(_vm.loading)?_c('b-spinner',{attrs:{"small":""}}):_c('span',[_vm._v(_vm._s(_vm.text))])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }