<template>
  <b-container fluid>
    <h1>Landing</h1>
  </b-container>
</template>

<script>
export default {
  name: 'landing',
  components: {},
};
</script>

<style lang="scss">
.bg-text {
  color: $HEALTH_THINK_COLOR;
}
</style>
